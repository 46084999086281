@media(max-width:1399px) {  
  .rocket-animation {
    width: 37% !important;
    right: 50px !important;
    top: -70px !important;
  }
}
@media(max-width:1199px) {
    .logo svg {
        width: 140px !important;
    }
    .about-card-img {
        right: 0px !important;
    }
    .card-content-wrap {
        padding: 50px 70px;
    }
    .common-space{
        padding: 55px 0;
    }
    // .pop-mobile {
    //     top: 90px;
    //     left: -60px !important;
    //   }
    .footer-logo{
        width: 170px;
    }
    .rocket-animation {
      width: 44% !important;
    right: 10px !important;
    top: -68px !important;
    }
    .tabWrapper{
      gap: 0;
    }
    .tabWrapper > div{
      padding-left: 10px !important;  
      padding-right: 10px !important;
    }
    .nav-bar-list{
      gap: 20px !important;
    }
}
@media(max-width:991px) {
    .nav-bar-list{
        display: none !important;
    }
    .toggleBtn {
        display: block !important;
        cursor: pointer;
        transition: all 0.5s cubic-bezier(.77,0,.18,1);
    }
    .toggleBtn svg{
        color: var(--themeColor);
    }
    .showToggle{
        position: absolute !important;
        top: 60px;
        left: 0;
        background: var(--white) !important;
        transition: all 0.5s cubic-bezier(.77,0,.18,1);
        color: var(--white) !important;
        width: 100% !important;
        display: block !important;
        box-shadow: 0px 5px 15px #dbdbdbd6;
    }
    .showToggle div{
        border-bottom: 1px solid var(--themeColor);
        outline: none;
        width: 100%;
        padding: 0;
    }
    .nav-bar-list a {
      padding: 10px 15px !important;
      display: flex;
      width: 100%;
    }
    .nav-bar-list a:before {
      display: none !important;
    }
    .showToggle a:hover{
        color: var(--themeTextGreadient);
    }
    .showToggle  .active{
        color: var(--themeTextGreadient);
    }
    .showToggle .nav-bar-list a:focus{
        color: var(--themeTextGreadient);
        background: none !important;
        &::before, &.active::before{
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 0;
          height: 0;
          background: transparent !important;
      }
    }
     .sticky{
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    } 
    .banner-bg{
        height: auto !important;
    }
    .banner-card {
        gap: 30px !important;
      }
    .bannerImg {
        text-align: center !important;
    }
    .pop-website {
        bottom: 60px;
        left: 0px !important;
    }
    .pop-social {
        top: 70px;
        bottom: 0;
        right: 0 !important;
    }
    .about-text-card {
        gap: 40px !important;
    }
    .about-card-img {
        right: 0px !important;
        width: 35% !important;
        top: auto !important;
        bottom: -20px;
    }
    .card-content-wrap  p{
        width: 60% !important;
    }
    .service-card-wrap{
        padding: 40px 20px !important;
         .service-card-content{
            width: 100% !important;
        }
    }
    .project-digital{
        border-radius: 20px !important;
        &::after {
            display: none !important;
        }
    }
    .project-other{
        border-radius: 20px !important;
    }
    .comparing> .row{
        gap: 30px;
    }
    .vector {
        top: 20px;
        left: 10px;
    }
    .vector2 {
        right: 10px;
    }
    iframe {
        width: 100%;
        height: 350px !important;
        margin-top: 0 !important;
    }
    .footer-content{
        flex-wrap: wrap;
        justify-content: center !important;
        gap: 25px !important;
    }
    .rocket-animation {
      display: none;
    }
    .tabWrapperContainer{
      display: flex;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    .tabWrapper{
      gap: 10px;
      width: auto;
      margin-bottom: 0;
    }
    .tabWrapper > div{
      padding-left: 5px !important;  
      padding-right: 5px !important;
    }
    
}
@media(max-width:768px) {
    .heading{
        font-size: 55px !important;
    }
    .banner-text-card{
        justify-content: center !important;
        &>div{
            justify-content: center !important;
            text-align: center !important;
            &>p{
                text-align: center;
                margin: 15px auto;
            }
        }
    }
    .slick-dots {
        bottom: -30px !important;
        text-align: center !important;
    }
    .about-content-wrap {
        justify-content: center !important;
        text-align: center !important;
        .btn-gradient-bg{
            justify-content: center !important;
        }
    }
    .card-content-wrap {
        padding: 50px 20px;
      }
    .common-space {
        padding: 40px 0;
      }
      .subHeading{
        font-size: 15px !important;
    }
    .themeTextGradient{
        font-size: 35px !important;
    }
    .heading-text-response{
        font-size: 35px !important;
    }
    .slick-next {
        right: -35px;
      }
      .slick-prev {
        left: -35px;
    }
      .slider-nav-container {
        max-width: 270px;
    }
    a:focus::before, a:hover.active::before, .nav-bar-list a.active.active::before, .nav-bar-list a:focus.active::before {
      background:  none !important;
  }
    .popup {
        position: absolute;
        z-index: 10;
        width: 170px !important;
    }
    .bannerImg span> img{
            width: 300px !important;
    }
    .service-img-wrap{
        display: none;
    }
    .location-card {
      gap: 20px !important;
    }
    .footer-list {
      gap: 20px !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
    }
    .footer-revised{
      gap: 0px !important;
    }
    
}
@media (max-width: 576px){
    .sub-heading{
        font-size: 18px !important;
    }
    .pop-mobile {
        top: 70px !important;
        left: 0;
    }
    .pop-website {
        bottom: 50px !important;
    }
    .subHeading {
        font-size: 13px !important;
      }
      .themeTextGradient {
        font-size: 30px !important;
      }
      .heading-text-response {
        font-size: 25px !important;
      }
      .card-content-wrap {
        padding: 40px 40px;
      }
      .service-card-content {
        gap: 5px !important;
      }
      .service-card {
        height: auto !important;
      }
      .service-img-wrap{
        display: none;
      }
      .sub-heading-text-response{
        font-size: 18px !important;
      }
      .common-space {
        padding: 15px 0;
      }
      .card-content-wrap,
      .service-card-wrap .service-card-content {
        padding: 30px;
        text-align: center !important;
        justify-content: center !important;
        align-items: center !important;
      }
      .service-card-wrap {
        padding: 20px 0px !important;
      }
      .about-card-img {
        right: -30px !important;
      }
      .service-card-wrap .service-card-content .heading-text-response{
        font-size: 28px !important;
      }
      .about-text-card{
        padding-top: 30px !important;
      }
      .my-5{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
      }
      p {
        font-size: 13px !important;
      }
      .project-digital > span,
      .project-other>span{
        font-size: 24px !important;
      }
      #portfolio .slick-slide .lazy-load-image-background{
        width: 100%;
        & img{
            width: 100%;
          }
      } 
       #portfolio .slick-prev, #portfolio .slick-next {
        position: absolute !important;
        top: auto !important;
        bottom: -50px !important;
        left: 0;
        right: 0;
        margin: auto;
      }
       #portfolio .slick-next{
        left: 70px !important;
      }
       #portfolio .slick-prev{
        right: 70px !important;
      }
      #portfolio .my-5{
        margin-top: 3rem !important;
        margin-bottom: 1.5rem !important;
      }
      .vector,
      .vector2 {
        background-size: 60% !important;
      }
      
      .tesSlideContentCol {
        width: 100%;
      }
      .project-content>div span{
        font-size: 16px !important;
      }
      .banner-text {
        width: 100% !important;
      }
      .banner-text-card > div {
        flex-wrap: wrap !important;
      }
      .nav-btn>div{
        display: none !important;
      }  
      .social-link{
        display: none !important;
      }
      .about-card-img{
        display: none !important;
      }
      .project-digital,
      .project-other {
        padding: 70px 20px 0px 20px !important;
      }
      .typing-effect {
        margin: 0 auto;
      }
      .footer-list{
        display: none !important;
      }
      .footer-revised {
        gap: 10px !important;
      }
}
@media (max-width: 480px){

    .heading {
        font-size: 50px !important;
        line-height: 40px !important;
      }
      .popup {
        width: 130px !important;
      }
      .themeTextGradient {
        font-size: 25px !important;
        line-height: 35px !important;
      }
      .card-content-wrap p {
        width: 100% !important;
      }
      .bannerImg span > img {
        width: 260px !important;
      }
      .service-card-wrap .service-card-content .heading-text-response {
        font-size: 25px !important;
      }
      .card-content-wrap, .service-card-wrap .service-card-content {
        padding: 25px;
      }
      .footer-revised {
        gap: 3px !important;
      }
      .vector, .vector2 {
        background-size: 50% !important;
      }
      .vector2 {
        right: 0px;
        bottom: -30px;
      }
      .modal-content{
        margin-top: 18%;
    }
      
}
@media (max-width: 400px){

    .bannerImg span > img {
        width: 200px !important;
      }
      .popup {
        width: 120px !important;
      }
      .pop-mobile {
        top: 40px !important;
        left: 0;
      }
      .pop-website {
        bottom: 20px !important;
      }
      .pop-social {
        top: 20px !important;
      }
      .heading-text-response {
        font-size: 22px !important;
      }
      .sub-heading-text-response {
        font-size: 16px !important;
      }
      .service-card-wrap .service-card-content .heading-text-response {
        font-size: 22px !important;
      }
      .vector2 {
        right: -20px;
      }
      .card-content-wrap, .service-card-wrap .service-card-content {
        padding-bottom: 18px;
        padding-top: 15px;
        padding-left: 0;
        padding-right: 0;

      }
      .project-digital,
      .project-other {
        padding: 50px 10px 0px 10px !important;
      }
      
        .service-card-content span img{
        position: relative;
        right: 10px;
      }
      .vector, .vector2{
        background-size: 40% !important;
        width:80px;
        height:80px;
      }
      .vector2{
        right: -30px;
        bottom: -30px;
      }
      .modal-content{
        margin-top: 15%;
    }
    .footer-revised {
      gap: 0px !important;
    }


   
}
   

    /***************** RTL DIrection*************************/


    
@media(max-width:1199px) {

    .rtl .arabic-pop-mobile {
        right: -60px;
    }
    .rtl .arabic-pop-social {
        left: -40px;
    }

}
@media (max-width: 991px){

    .rtl .arabic-pop-mobile {
        right: 40px;
      }
      .rtl .arabic-pop-website {
        right: 0px;
        left: auto;
      }
      .rtl .arabic-pop-social {
        left: 0px;
      }
      .rtl .subHeading{
        line-height: 20px !important;
      }
      .rtl .project-digital,
      .rtl .project-other {
        border-radius: 20px !important;
      }
}
@media (max-width: 768px){
    .rtl .arabic-popup {
        width: 170px !important;
      }
    .rtl .arabic-pop-mobile {
        top: 60px;
        right: 0px;
      }
      .rtl .arabic-pop-website {
        bottom: 50px;
      }
    
}
@media (max-width: 576px){

  .rtl .slick-dots {
    text-align: center !important;
  }
  .rtl #portfolio .slick-prev, .rtl #portfolio .slick-next {
    position: absolute !important;
    top: auto !important;
    bottom: -40px !important;
    left: 0;
    right: 0;
    margin: auto;
  }
  .rtl #portfolio .slick-next{
    right: 130px !important;
  }
  .rtl  #portfolio .slick-prev{
    left: 130px !important;
  }
    .rtl .project-digital,
    .rtl .project-other {
        padding: 70px 20px 0px 20px !important;
      }
      .rtl .banner-card .heading {
        line-height: 45px !important;
      }
 }
@media (max-width: 400px){

  .rtl .arabic-pop-mobile {
    top: 30px;
    right: 0;
  }
  .rtl .arabic-popup {
    width: 120px !important;
  }
  .rtl .arabic-pop-social {
    left: -20px;
  }
  .rtl .arabic-pop-website {
    bottom: 20px;
  }
  .rtl .project-digital, .rtl .project-other {
    padding: 50px 10px 0px 10px !important;
  }
  
 
 }
